/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import styles from './TheOriginHeader.module.scss';
import TheOriginLogo from '../../assets/images/theorigin.svg';

const TheOriginHeader = () => {
  return (
    <div className={styles.the_origin_header}>
      <img
        src={TheOriginLogo}
        alt="logo"
        onClick={() => {
          // window.location.href = 'https://theorigin.kr';
          window.open('https://theorigin.kr', '_blank');
        }}
      />
      <p
        onClick={() => {
          window.location.href = '/';
        }}
      >
        나혼자만렙키우기
      </p>
    </div>
  );
};

export default TheOriginHeader;
