import styles from './Landing.module.scss';

import AppstoreFooterButton from '../../assets/images/appstore_button_footer.png';
import GoogleplayFooterButton from '../../assets/images/googleplay_button_footer.png';
import TheoriginLogo from '../../assets/images/theorigin_logo_white.svg';

import Header from '../common/Header';
import TheOriginHeader from '../common/TheOriginHeader';

import Char1 from '../../assets/images/char1.png';
import Char2 from '../../assets/images/char2.png';
import minChar1 from '../../assets/images/minchar1.png';
import minChar2 from '../../assets/images/minchar2.png';
import Logo from '../../assets/images/logo_big.png';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Landing = () => {
  const [lang, setLang] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    setLang(navigator.language);
    console.log('data', navigator.language);
    window.addEventListener('resize', handleResize);
    // 컴포넌트 언마운트 시 이벤트 리스너 삭제
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const checkTos = () => {  
    //위치 한국일 경우: 'ko-KR', 일본일 경우: 'ja-JP', 그외 국가 영문 시간없어서 그냥 분기처리 if, else 로 처리.
    if( lang === 'ko-KR') { 
      window.open(
        'https://dashboard-api.gamepot.ntruss.com/pages/cc81ffdc-d3a2-41bc-b11d-efedb814c6b9?language=ko',
        '_blank',
      );
    } else if( lang === 'ja-JP') { 
      window.open(
        'https://dashboard-api.gamepot.ntruss.com/pages/cc81ffdc-d3a2-41bc-b11d-efedb814c6b9?language=ja',
        '_blank',
      );
    } else { 
      window.open(
        'https://dashboard-api.gamepot.ntruss.com/pages/cc81ffdc-d3a2-41bc-b11d-efedb814c6b9',
        '_blank',
      );
    }

  };

  const checkPolicy = () => { 
    if( lang === 'ko-KR') { 
      window.open(
        'https://dashboard-api.gamepot.ntruss.com/pages/27571422-dd36-4222-b11b-02fcadf97b1f?language=ko',
        '_blank',
      );
    } else if( lang === 'ja-JP') { 
      window.open(
        'https://dashboard-api.gamepot.ntruss.com/pages/27571422-dd36-4222-b11b-02fcadf97b1f?language=ja',
        '_blank',
      );
    } else { 
      window.open(
        'https://dashboard-api.gamepot.ntruss.com/pages/27571422-dd36-4222-b11b-02fcadf97b1f',
        '_blank',
      );
    }
  };

  
  return (
    <div className={styles.wrapper}>
      <TheOriginHeader />
      <Header />
      <div className={styles.container}>
        <div className={styles.background}>
          { windowWidth < 600 ? <img src={minChar1} alt="char1" className={styles.char1} /> : <img src={Char1} alt="char1" className={styles.char1} /> }
          { windowWidth < 600 ? <img src={minChar2} alt="char1" className={styles.char2} /> : <img src={Char2} alt="char1" className={styles.char2} /> }
          <div className={styles.center}>
            <img src={Logo} alt="logo" className={styles.logo} />
          </div>
          <div className={styles.appstore}>
            <Link to="https://apps.apple.com/app/id6566177585" target="_blank">
              <img src={AppstoreFooterButton} alt="appstore" className={styles.img1} />
            </Link>
            <Link to="https://play.google.com/store/apps/details?id=group.theorigin.themaxlevelreturner" target="_blank">
              <img src={GoogleplayFooterButton} alt="googleplay" className={styles.img2} />
            </Link>
          </div>
        </div>
        <div className={styles.background2} style={{ color: 'white', letterSpacing: '0.36' }}>
          <div className={styles.textTop}>
            <text className={styles.textStory}>STORY</text> 
          </div>
          <div className={styles.textMain}>
            <div className={styles.textMt42}>
              <text>게임 내에서 보스 바알 공략에 번번이 실패하던 주인공 윤상혁과 동료들은</text>
            </div>
            <div>
              <text>그 날도 여느 때와 같이 실패의 고배를 마시고 각자의 현생을 살러 간다.</text>
            </div>
            <div className={styles.textMt20}>
              <text> 갑자기 하늘에서 떨어진 빛기둥으로 인해 게임의 플레이어들은 게임 속으로 빨려 들어가고,</text>
            </div>
            <div>
              <text>이렇게 빨려들어간 사람의 수는 전 세계적으로 1억 2천만 명이 되었다.</text>
            </div>
            <div className={styles.textMy}>
              <div>
                <text>"!!!!!!!?"</text>
              </div>
              <div>
                <text>"여긴... 이터널 월드랑 똑같이 생겼잖아?"</text>
              </div>
              <div>
                <text>"대체 이게 무슨 일이야...!"</text>
              </div>
            </div>
            <div className={styles.textMt20}>
              <div>
                <text>당황할 새도 없이 몬스터를 조우한 주인공은,</text>
              </div>
              <div>
                <text>이터널 월드에서 그랬던 것처럼 나뭇가지를 무기삼아 몬스터를 해치우기 시작한다.</text>
              </div>
            </div>
            <div className={styles.textMt20}>
              <div>
                <text>
                  이터널 월드에서 동료 이철우를 만난 주인공은 모루석이라는 재화를 제공하고
                </text>
              </div>
              <div>
                <text>
                  각종 장비를 얻게 되어 점차적으로 강해 진다.
                </text>
              </div>
            </div>
            <div className={styles.textMt20}>
              <div>
                <text>몽마의 성에 도착한 주인공은, 이터널 월드 플레이어로서의 경험을 살려</text>
              </div>
              <div>
                <text>몽마의 서큐버스 퀸 릴리스와의 전투에서 승리하고, 몽마의 성을 얻게 된다.</text>
              </div>
            </div>
            <div className={styles.textMt20}>
              <div>
                <text>
                  이후 릴리스와 함께 모험하게 된 주인공은 보스 바알을 격파하고 현실로 돌아가기 위해
                </text>
              </div>
              <div>
                <text>
                  이터널 월드 속에서 자신의 동료들을 모으고 장비를 강화하는데...!
                </text>
              </div>
            </div>

          </div>

        </div>
        <div className={styles.footer}>
          <div className={styles.footer_left}>
            <div className={styles.footer_row}>
              <div className={styles.footer_logo}>
                <img src={TheoriginLogo} alt="logo" />
              </div>
            </div>
            <div className={styles.footer_row}>
              <div
                className={styles.footer_text}
                style={{
                  cursor: 'pointer',
                  textDecorationLine: 'underline',
                  color: 'rgba(255,255,255,1)',
                }}
                onClick={ () => checkTos()}
              >
                이용약관
              </div>
              <div
                className={styles.footer_text}
                style={{
                  cursor: 'pointer',
                  textDecorationLine: 'underline',
                  color: 'rgba(255,255,255,1)',
                }}
                onClick={() => checkPolicy()}
              >
                개인정보처리방침
              </div>
              <div
                className={styles.footer_text}
                style={{
                  cursor: 'pointer',
                  textDecorationLine: 'underline',
                  color: 'rgba(255,255,255,1)',
                }}
              // onClick={()=>{}}
              >
                <Link to="mailto:support@theorigin.kr" target="_blank">
                  CONTACT
                </Link>
              </div>
            </div>
            <div className={styles.footer_row}>
              <div className={styles.footer_text}>
                225, 80-90, UN village-gil, Yongsan-gu, Seoul, Korea
              </div>
            </div>
            <div className={styles.footer_row}>
              <div className={styles.footer_text}>
                © The Origin Inc. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
