/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import AOS from 'aos';
import styles from './Header.module.scss';
import SoloLogo from '../../assets/images/solo_logo.png';
import { useEffect, useState } from 'react';

const Header = () => {
  const [show, setShow] = useState(false);

  const handleMouse = (e: any) => {
    if (e.clientY < 100) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    AOS.init();
    window.addEventListener('mousemove', handleMouse);
    return () => {
      window.removeEventListener('mousemove', handleMouse);
    };
  }, []);

  return (
    <>
      {show && (
        <div className={styles.header} data-aos="fade-down">
          <img
            src={SoloLogo}
            className={styles.solo_logo}
            alt="logo"
            onClick={() => {
              // window.location.href = 'https://theorigin.kr';
              window.location.href = '/';
            }}
          />
          {/* <img
        src={TheOriginLogo}
        alt="logo"
        onClick={() => {
          // window.location.href = 'https://theorigin.kr';
          window.open('https://theorigin.kr', '_blank');
        }}
      />
      <p
        onClick={() => {
          window.location.href = '/';
        }}
      >
        나혼자만렙키우기
      </p> */}
        </div>
      )}
    </>
  );
};

export default Header;
