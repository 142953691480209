import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Landing from './components/pages/Landing';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          {/* <Route path="/characters" element={<Characters />} />
          <Route path="/features" element={<Features />} />
          <Route path="/outlook" element={<Outlook />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
